import React from "react";
import ServicesSidebar from "./ServicesSidebar";
import WhatWeDo from "./WhatWeDo";

const AsusContent = () => {
  return (
    <section class="blog-sidebar">
      <div class="container">
        <div class="section-title section-title--two text-center">
          <span class="section-title__tagline">WHAT WE FIXING</span>
          <h2 class="section-title__title">Providing device solutions</h2>
          <p class="section-title__text">
            Precision in every click, perfection in every fix - that's our
            service promise
          </p>
        </div>
        <div class="row">
          <div class="col-xl-8 col-lg-7">
            <div class="blog-sidebar__left">
              <div class="blog-sidebar__img-box">
                <div class="blog-sidebar__img">
                  <img src="assets/images/blog/blog-sidebar-img-1.jpg" alt="" />
                </div>
                <div class="blog-sidebar__video-link">
                  <a
                    href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                    class="video-popup"
                  >
                    <div class="blog-sidebar__video-icon">
                      <span class="fa fa-play"></span>
                      <i class="ripple"></i>
                    </div>
                  </a>
                </div>
              </div>
              <p class="blog-sidebar__text-1">
                Asus Laptop Service Center in Bhavani provides convenient
                service for your Asus laptop problems. Our Experts have vast
                experience in the laptop service industry, allowing us to
                deliver cost-effective and quality repair service solutions for
                all of your Asus laptop technical and gadget fix needs at
                nominal cost without compromising the quality service. Our
                Laptop Service technicians are highly trained experts, who have
                much expertise in the Asus laptop chip-level service, Asus
                Motherboard service, Asus hard disk replacement service, Hinges
                rework and all Asus Laptop issues.
              </p>
              <p class="blog-sidebar__text-2">
                Asus laptop Service in Komarapalayam has solutions for many of
                your laptop services and requirements. Techspo Laptop Service
                Center serves out of all model Asus Laptop Service Center in
                Erode like Asus Inspiron, Asus Alienware, Asus Vostro, Asus
                Studio, Asus Xps, Asus Lattitude etc.,
              </p>

              <div class="blog-sidebar__img-box-2">
                <div class="row">
                  <div class="col-xl-6">
                    <div class="blog-sidebar__single">
                      <div class="blog-sidebar__img-1">
                        <img
                          src="assets/images/blog/blog-sidebar-img-2.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="blog-sidebar__single">
                      <div class="blog-sidebar__img-2">
                        <img
                          src="assets/images/blog/blog-sidebar-img-3.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <WhatWeDo />
            </div>
          </div>
          <div class="col-xl-4 col-lg-5">
            <div class="sidebar">
              <div class="sidebar__single sidebar__category">
                <h3 class="sidebar__title">Laptop Accessories</h3>
                <ul class="sidebar__category-list list-unstyled">
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Adapter
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Battery
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Screen
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Keyboard
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Panels
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Motherboard
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Touchpad
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Cooling Fan
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Speakers
                    </a>
                  </li>
                </ul>
              </div>
              <div class="sidebar__single sidebar__category">
                <h3 class="sidebar__title">Asus Laptop Major Services</h3>
                <ul class="sidebar__category-list list-unstyled">
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Motherboard
                      Service
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Hard Disk
                      Service
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Hinges
                      Re-work
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Keyboard
                      Replacement
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Screen
                      Replacement
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Battery
                      Replacement
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Panels
                      Replacement
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop RAM / SSD
                      Upgradation
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop OS
                      Installation
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop No Power On
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Bios Password
                      Lock
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Power On No
                      Display
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Auto Power
                      Off
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Not Booting
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop WiFi/Network
                      Problems
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Overheating
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop Keyboard
                      Malfunction
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Asus Laptop USB Port Not
                      Working
                    </a>
                  </li>
                </ul>
              </div>

              <ServicesSidebar brand='Asus Laptop'/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AsusContent;
