import React from 'react'
import AcerHeader from '../Laptop/AcerHeader'
import WorkingProcess from '../Laptop/WorkingProcess'
import AcerContent from '../Laptop/AcerContent'

const Acer = () => {
  return (
   <>
   <AcerHeader/>
   <WorkingProcess/>
   <AcerContent/>
   </>
  )
}

export default Acer