import React from 'react'
import SamsungHeader from '../Laptop/SamsungHeader'
import WorkingProcess from '../Laptop/WorkingProcess'
import SamsungContent from '../Laptop/SamsungContent'

const Samsung = () => {
  return (
    <>
    <SamsungHeader/>
    <WorkingProcess/>
    <SamsungContent/>
    </>
  )
}

export default Samsung