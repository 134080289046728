import React from "react";

export default function Testimonials() {
  return (
    <section className="testimonial-one">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="section-title text-left">
              <span className="section-title__tagline">OUR HAPPY CLIENTS</span>
              <h2 className="section-title__title">Our Customers Review</h2>
            </div>
            <div
              className="testimonial-one__carousel owl-carousel owl-theme thm-owl__carousel"
              data-owl-options='{
                        "loop": true,
                        "autoplay": false,
                        "margin": 30,
                        "nav": true,
                        "dots": false,
                        "smartSpeed": 500,
                        "autoplayTimeout": 10000,
                        "navText": ["<span class=\"icon-left-arrow\"></span>","<span class=\"icon-right-arrow\"></span>"],
                        "responsive": {
                            "0": {
                                "items": 1
                            },
                            "768": {
                                "items": 1
                            },
                            "992": {
                                "items": 1
                            },
                            "1200": {
                                "items": 1
                            }
                        }
                    }'
            >
              <div className="item">
                <div className="testimonial-one__single">
                  <div className="testimonial-one__client-img-box">
                    <div className="testimonial-one__client-img">
                      <img
                        src="assets/images/testimonial/testimonial-1-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="testimonial-one__client-info-box">
                    <div className="testimonial-one__quote">
                      <span className="icon-quote"></span>
                    </div>
                    <p className="testimonial-one__text">
                      My experience at some service centre in bhavani was far
                      from pleasant. So Finally visited TECKSPO last week, and I
                      must say, I am thoroughly impressed with his service. From
                      the moment I walked in, he was incredibly helpful and
                      knowledgeable. He diagnosed and fixed my laptop's issues
                      in a timely manner, and the pricing was fair. The laptop
                      now works flawlessly. I highly recommend this service
                      center to anyone in need of laptop repairs in bhavani
                      surroundings.. Thank you for your excellent service Mr.
                      Sasi.!
                    </p>
                    <div className="testimonial-one__client-info">
                      <h3>Boopathiraja G</h3>
                      {/* <p>Graphic Designer</p> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="testimonial-one__single">
                  <div className="testimonial-one__client-img-box">
                    <div className="testimonial-one__client-img">
                      <img
                        src="assets/images/testimonial/testimonial-1-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="testimonial-one__client-info-box">
                    <div className="testimonial-one__quote">
                      <span className="icon-quote"></span>
                    </div>
                    <p className="testimonial-one__text">
                      I was searching for a trustworthy service person or center
                      near my home in Bhavani. Came across TECHSPO through
                      Google maps. I was quite skeptical when you see the 4 and
                      5 stars rating thinking all these were fake reviews but I
                      was wrong . The service was prompt quick and gave my
                      laptop they fixed it within a quick span of time.
                    </p>
                    <div className="testimonial-one__client-info">
                      <h3>Pranesh DK</h3>
                      {/* <p>Graphic Designer</p> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="testimonial-one__single">
                  <div className="testimonial-one__client-img-box">
                    <div className="testimonial-one__client-img">
                      <img
                        src="assets/images/testimonial/testimonial-1-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="testimonial-one__client-info-box">
                    <div className="testimonial-one__quote">
                      <span className="icon-quote"></span>
                    </div>
                    <p className="testimonial-one__text">
                      Great Experience. Sasi, the technician who really
                      understands the problem and provided me with the solution.
                      He didn't simply try to sell new spare parts rather
                      perfectly thinks from the customers' perspective and does
                      his job really well. I must say, this is the go to place
                      for any pc ailment.
                    </p>
                    <div className="testimonial-one__client-info">
                      <h3>Narendra Prasath</h3>
                      {/* <p>Graphic Designer</p> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="testimonial-one__single">
                  <div className="testimonial-one__client-img-box">
                    <div className="testimonial-one__client-img">
                      <img
                        src="assets/images/testimonial/testimonial-1-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="testimonial-one__client-info-box">
                    <div className="testimonial-one__quote">
                      <span className="icon-quote"></span>
                    </div>
                    <p className="testimonial-one__text">
                      Best Laptop service center in Bhavani and it's nearby. I
                      already tried fixing my laptop with another service centre
                      but waste of money. Sasi fixed it nicely with his general
                      service itself even during Sunday and Diwali holiday. If I
                      provided my laptop to the branded service centre they
                      would have charged me in thousands by replacing my hard
                      disk unnecessarily. Now my laptop is working nicely whole
                      day without any problem for couple of weeks. Thank you
                      Sasi !!!! Great work!!! P.S. I found him only through
                      Google 🙂
                    </p>
                    <div className="testimonial-one__client-info">
                      <h3>G B</h3>
                      {/* <p>Graphic Designer</p> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="testimonial-one__single">
                  <div className="testimonial-one__client-img-box">
                    <div className="testimonial-one__client-img">
                      <img
                        src="assets/images/testimonial/testimonial-1-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="testimonial-one__client-info-box">
                    <div className="testimonial-one__quote">
                      <span className="icon-quote"></span>
                    </div>
                    <p className="testimonial-one__text">
                      Excellent service done at affordable price. Trusted
                      service. Good response for the doubts. Technician has good
                      knowledge in quick troubleshooting and fixing. Timely
                      delivery.
                    </p>
                    <div className="testimonial-one__client-info">
                      <h3>Ramesh Krishnan S</h3>
                      {/* <p>Graphic Designer</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
