import React from "react";

const ContactContent = () => {
  return (
    <section class="contact-page">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6">
            <div class="contact-page__left">
              <div class="contact-page__shape-1">
                <img
                  src="assets/images/shapes/contact-page-shape-1.png"
                  alt=""
                />
              </div>
              <h3 class="contact-page__title">Leave a message</h3>
              <form
                action="assets/inc/sendemail.php"
                class="contact-page__form contact-form-validated"
                novalidate="novalidate"
              >
                <div class="row">
                  <div class="col-xl-6">
                    <div class="contact-page__form-input-box">
                      <input type="text" placeholder="Name" name="name" />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="contact-page__form-input-box">
                      <input type="email" placeholder="Email" name="email" />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="contact-page__form-input-box">
                      <input type="text" placeholder="Subject" name="Subject" />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="contact-page__form-input-box">
                      <input
                        type="text"
                        placeholder="Phone"
                        name="Phone Number"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="contact-page__form-input-box text-message-box">
                      <textarea
                        name="message"
                        placeholder="Your Query"
                      ></textarea>
                    </div>
                    <div class="contact-page__btn-box">
                      <a href="/" class="thm-btn contact-page__btn">
                        Send Message
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6">
            <div class="contact-page__right">
              <div class="section-title text-left">
                <span class="section-title__tagline">GET IN TOUCH</span>
                <h2 class="section-title__title">
                  Connecting you to the world, one device at a time
                </h2>
              </div>
              <p class="contact-page__right-text">
                Thank you for choosing Techspo Services for your laptop and
                Desktop repair needs. We look forward to serving you and keeping
                your devices in optimal condition.
              </p>
              <div class="contact-page__points-box-inner">
                <div class="contact-page__points-box">
                  <h3 class="contact-page__points-title">Erode</h3>
                  <ul class="contact-page__points-list list-unstyled">
                    <li>
                      <div class="icon">
                        <span class="icon-send"></span>
                      </div>
                      <div class="text">
                        <p>
                          8/547D, First Floor, Near JIO Petrol Bunk,
                          Lakshmi Nagar, Bhavani, Erode - 638316
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="icon">
                        <span class="icon-mail"></span>
                      </div>
                      <div class="text">
                        <p>
                          <a href="mailto:techsposervices@gmail.com">
                            techsposervices@gmail.com
                          </a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="icon">
                        <span class="icon-call"></span>
                      </div>
                      <div class="text">
                        <p>
                          <a href="tel:+916383664733">+91 63836 64733</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="icon">
                        <span class="icon-clock"></span>
                      </div>
                      <div class="text">
                        <p>09:00 AM - 22:00 PM</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="contact-page__points-box">
                  <h3 class="contact-page__points-title">Komarapalayam</h3>
                  <ul class="contact-page__points-list list-unstyled">
                    <li>
                      <div class="icon">
                        <span class="icon-send"></span>
                      </div>
                      <div class="text">
                        <p>
                          8/547D, First Floor, Near Reliance Petrol Bunk,
                          Lakshmi Nagar, Bhavani, Erode - 638316
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="icon">
                        <span class="icon-mail"></span>
                      </div>
                      <div class="text">
                        <p>
                          <a href="mailto:techsposervices@gmail.com">
                            techsposervices@gmail.com
                          </a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="icon">
                        <span class="icon-call"></span>
                      </div>
                      <div class="text">
                        <p>
                          <a href="tel:+916383664733">+91 63836 64733</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="icon">
                        <span class="icon-clock"></span>
                      </div>
                      <div class="text">
                        <p>09:00 AM - 22:00 PM</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactContent;
