import React from "react";
import GovernmentHeader from "../Laptop/GovernmentHeader";
import WorkingProcess from "../Laptop/WorkingProcess";
import GovernmentContent from "../Laptop/GovernmentContent";

const Government = () => {
  return (
    <>
      <GovernmentHeader />
      <WorkingProcess />
      <GovernmentContent />
    </>
  );
};

export default Government;
