import React from "react";

const AboutHeader = () => {
  return (
    <section class="page-header">
      <div
        class="page-header-bg"
        style={{
          backgroundImage: "url(assets/images/backgrounds/page-header-bg.jpg)",
        }}
      ></div>
      <div class="container">
        <div class="page-header__inner">
          <h2>About Us</h2>
          <p>Your Trusted Tech Partner</p>
        </div>
      </div>
    </section>
  );
};

export default AboutHeader;
