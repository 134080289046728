import React from "react";

function Introduction() {
  return (
    <section className="about-one">
      <div
        className="about-one__bg float-bob-y"
        style={{backgroundImage: "url(assets/images/backgrounds/about-one-bg-img-1.jpg)"}}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="about-one__left">
              <div
                className="about-one__img wow slideInLeft"
                data-wow-delay="100ms"
                data-wow-duration="2500ms"
              >
                <img src="assets/images/resources/about-1-1.jpg" alt="" />
                <div className="about-one__our-goal">
                  <p className="about-one__our-goal-sub-title">Our goal:</p>
                  <h3 className="about-one__our-goal-title">
                    "We innovate, till we make it"
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="about-one__right">
              <div className="section-title text-left">
                <span className="section-title__tagline">OUR INTRODUCTION</span>
                <h2 className="section-title__title">
                  Welcome To Techspo Services
                </h2>
              </div>
              <p className="about-one__right-text-1">
                We pride ourselves on being more than just a laptop and mobile
                service provider; we are your dedicated tech partner committed
                to delivering exceptional service and unmatched expertise.
              </p>
              <ul className="about-one__points list-unstyled">
                <li>
                  <div className="about-one__points-single">
                    <div className="about-one__points-icon">
                      <span className="icon-repair"></span>
                    </div>
                    <div className="about-one__points-text">
                      <h3 className="about-one__points-title">
                        <a href="/">Repair Device</a>
                      </h3>
                      <p className="about-one__points-subtitle">
                        Reviving your devices, renewing your connections.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="about-one__points-single">
                    <div className="about-one__points-icon">
                      <span className="icon-phone"></span>
                    </div>
                    <div className="about-one__points-text">
                      <h3 className="about-one__points-title">
                        <a href="/">Replace Device</a>
                      </h3>
                      <p className="about-one__points-subtitle">
                        Elevate your tech lifestyle with premium accessories
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <a href="/" className="thm-btn">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Introduction;
