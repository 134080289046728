import React from "react";

function Slider() {
  return (
    <>
      <section className="main-slider clearfix">
        <div
          className="swiper-container thm-swiper__slider"
          data-swiper-options='{"slidesPerView": 1, "loop": true,
                "effect": "fade",
                "pagination": {
                "el": "#main-slider-pagination",
                "type": "bullets",
                "clickable": true
                },
                "navigation": {
                "nextEl": "#main-slider__swiper-button-next",
                "prevEl": "#main-slider__swiper-button-prev"
                },
                "autoplay": {
                "delay": 5000
                }}'
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div
                className="image-layer"
                style={{
                  backgroundImage:
                    "url(assets/images/backgrounds/main-slider-1-1.jpg)",
                }}
              ></div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="main-slider__content">
                      <h2 className="main-slider__title">
                        Welcome to Techspo Services
                      </h2>
                      <p className="main-slider__text">
                        Where innovation meets repair, your devices are in good
                        hands.
                      </p>
                      <div className="main-slider__btn-box">
                        <a
                          href="/"
                          className="thm-btn main-slider__btn"
                        >
                          Discover More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div
                className="image-layer"
                style={{
                  backgroundmage:
                    "url(assets/images/backgrounds/main-slider-1-2.jpg)",
                }}
              ></div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="main-slider__content">
                      <h2 className="main-slider__title">
                      We Have Than 6 Years Experience
                      </h2>
                      <p className="main-slider__text">
                      Laptop & Computer Repair Services
                      </p>
                      <div className="main-slider__btn-box">
                        <a
                          href="/"
                          className="thm-btn main-slider__btn"
                        >
                          Discover More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="image-layer"
                style={{
                  backgroundmage:
                    "url(assets/images/backgrounds/main-slider-1-2.jpg)",
                }}
              ></div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="main-slider__content">
                      <h2 className="main-slider__title">
                        Your devices, our expertise - a perfect match
                      </h2>
                      <p className="main-slider__text">
                        Empowering your digital world with expert service and
                        care.
                      </p>
                      <div className="main-slider__btn-box">
                        <a
                          href="/"
                          className="thm-btn main-slider__btn"
                        >
                          Discover More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div
                className="image-layer"
                style={{backgroundImage: "url(assets/images/backgrounds/main-slider-1-3.jpg)"}}></div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="main-slider__content">
                      <h2 className="main-slider__title">
                        Transforming your tech troubles into triumphs
                      </h2>
                      <p className="main-slider__text">
                        Repairing today for a connected tomorrow.
                      </p>
                      <div className="main-slider__btn-box">
                        <a
                          href="/"
                          className="thm-btn main-slider__btn"
                        >
                          Discover More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- If we need navigation buttons --> */}
          <div className="main-slider__nav">
            <div
              className="swiper-button-prev"
              id="main-slider__swiper-button-next"
            >
              <i className="icon-left-arrow"></i>
            </div>
            <div
              className="swiper-button-next"
              id="main-slider__swiper-button-prev"
            >
              <i className="icon-right-arrow"></i>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Slider;
