import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header/Header";
import Home from "./Pages/Home";
import Footer from "./Header/Footer";
import BottomtoTop from "./Header/BottomtoTop";
import About from "./Pages/About";
import Dell from "./Pages/Dell";
import HP from "./Pages/HP";
import Asus from "./Pages/Asus";
import Samsung from "./Pages/Samsung";
import Lenova from "./Pages/Lenova";
import Acer from "./Pages/Acer";
import Toshiba from "./Pages/Toshiba";
import Sony from "./Pages/Sony";
import Government from "./Pages/Government";
import Contact from "./Pages/Contact";
import DesktopService from "./Pages/DesktopService";
function App() {
  return (
    <div className="App">
      {/* <Preloader/> */}
      <div className="page-wrapper">
        <Header />
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/dell" element={<Dell />} />
            <Route path="/lenova" element={<Lenova />} />
            <Route path="/hp" element={<HP />} />
            <Route path="/acer" element={<Acer />} />
            <Route path="/asus" element={<Asus />} />
            <Route path="/toshiba" element={<Toshiba />} />
            <Route path="/sony" element={<Sony />} />
            <Route path="/samsung" element={<Samsung />} />
            <Route path="/governmentlaptop" element={<Government />} />
            <Route path="/desktopservice" element={<DesktopService />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Router>

        <Footer />
        <BottomtoTop />
      </div>
    </div>
  );
}

export default App;
