import React from "react";
import ContactHeader from "../Contact/ContactHeader";
import ContactContent from "../Contact/ContactContent";
import ContactMap from "../Contact/ContactMap";

const Contact = () => {
  return (
    <>
      <ContactHeader />
      <ContactContent />
      <ContactMap />
    </>
  );
};

export default Contact;
