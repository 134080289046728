import React from "react";

export default function Services() {
  return (
    <section className="services-one">
      <div className="services-one-shape-1 float-bob-x">
        <img src="assets/images/shapes/services-one-shape-1.png" alt="" />
      </div>
      <div className="services-one-shape-2 float-bob-y">
        <img src="assets/images/shapes/services-one-shape-2.png" alt="" />
      </div>
      <div className="container">
        <div className="section-title section-title--two text-center">
          <span className="section-title__tagline">OUR SERVICES</span>
          <h2 className="section-title__title">Our Efficient Solution</h2>
          <p className="section-title__text">
            Efficient Solutions, Exceptional Service - Techspo Services
          </p>
        </div>
        <div className="row">
          <div
            className="col-xl-4 col-lg-4 wow fadeInUp"
            data-wow-delay="100ms"
          >
            <div className="services-one__single">
              <div className="services-one__img">
                <img src="assets/images/services/services-1-1.jpg" alt="" />
                {/* <div className="services-one__icon">
                  <span className="icon-laptop"></span>
                </div> */}
              </div>
              <div className="services-one__content">
                <h3 className="services-one__title">
                  <a href="/">Laptop Repair</a>
                </h3>
                <p className="services-one__text">
                  Is your laptop acting up, slowing down, or refusing to
                  cooperate? We understand the frustration that comes with a
                  malfunctioning laptop, and we're here to provide expert repair
                  services that get you back to work or play in no time.
                </p>
                <div className="services-one__btn-box">
                  <a
                    href="/"
                    className="thm-btn services-one__btn"
                  >
                    Repair Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xl-4 col-lg-4 wow fadeInUp"
            data-wow-delay="300ms"
          >
            <div className="services-one__single">
              <div className="services-one__img">
                <img src="assets/images/services/services-1-3.jpg" alt="" />
                {/* <div className="services-one__icon">
                  <span className="icon-tap"></span>
                </div> */}
              </div>
              <div className="services-one__content">
                <h3 className="services-one__title">
                  <a href="/">Computer Repair</a>
                </h3>
                <p className="services-one__text">
                  Is your computer not performing up to par, or has it
                  encountered an unexpected issue? Our skilled technicians
                  will conduct a thorough diagnosis to identify and resolve any
                  hardware or software issues. Restoring your device to optimal
                  performance.
                </p>
                <div className="services-one__btn-box">
                  <a
                    href="/"
                    className="thm-btn services-one__btn"
                  >
                    Repair Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xl-4 col-lg-4 wow fadeInUp"
            data-wow-delay="200ms"
          >
            <div className="services-one__single">
              <div className="services-one__img">
                <img src="assets/images/services/services-1-2.jpg" alt="" />
                {/* <div className="services-one__icon">
                  <span className="icon-phone"></span>
                </div> */}
              </div>
              <div className="services-one__content">
                <h3 className="services-one__title">
                  <a href="/">Phone Repair</a>
                </h3>
                <p className="services-one__text">
                  Is your phone showing signs of wear and tear, or has it
                  suffered an unexpected mishap? We specialize in professional
                  phone repair services to bring your device back to life and
                  keep you connected. We're dedicated to keeping you connected.
                </p>
                <div className="services-one__btn-box">
                  <a
                    href="/"
                    className="thm-btn services-one__btn"
                  >
                    Repair Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
