import React from "react";

export default function Footer() {
  return (
    <footer className="site-footer">
      <div
        className="site-footer-shape-1 float-bob-y"
        style={{
          backgroundImage: "url(assets/images/shapes/logo_2_white.png)",
        }}
      ></div>
      <div className="site-footer__top">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="footer-widget__column footer-widget__about">
                <div className="footer-widget__logo">
                  <a href="#">
                    <img
                      src="assets/images/resources/logo-1.png"
                      width="100%"
                      alt=""
                    />
                  </a>
                </div>
                <div className="footer-widget__about-text-box">
                  <p className="footer-widget__about-text">
                  	Connecting You to The Digital World. Quality repairing at your Affordability
                  </p>
                </div>
                <div className="footer-widget__social-box">
                  <h4 className="footer-widget__social-title">
                    Stay Connected
                  </h4>
                  <div className="site-footer__social">
                   
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-facebook"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-youtube"></i>
                    </a>
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-2 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="footer-widget__column footer-widget__links">
                <div className="footer-widget__title-box">
                  <h3 className="footer-widget__title">Quick Links</h3>
                </div>
                <ul className="footer-widget__Explore-list list-unstyled">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About Us</a>
                  </li>
                  <li>
                    <a href="/dell">Laptop Service</a>
                  </li>
                  <li>
                    <a href="/desktopservice">Desktop Service</a>
                  </li>
                  {/* <li>
                    <a href="/contact">Mobile Service</a>
                  </li> */}
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="footer-widget__column footer-widget__services">
                <div className="footer-widget__title-box">
                  <h3 className="footer-widget__title">Laptop Services</h3>
                </div>
                <ul className="footer-widget__services-list list-unstyled">
                  <li>
                    <a href="/dell">Dell Laptop Service</a>
                  </li>
                  <li>
                    <a href="/lenova">Lenova Laptop Service</a>
                  </li>

                  <li>
                    <a href="/hp">HP laptop Service</a>
                  </li>
                  <li>
                    <a href="/acer">ACER laptop Service</a>
                  </li>
                  <li>
                    <a href="/governmentlaptop">Government Laptop Service</a>
                  </li>
                  {/* <li>
                    <a href="services-details.html">Smart Phone Service</a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="footer-widget__column footer-widget__Contact">
                <div className="footer-widget__title-box">
                  <h3 className="footer-widget__title">Contact Us</h3>
                </div>
                <ul className="footer-widget__Contact-list list-unstyled">
                  <li>
                    <div className="icon">
                      <span className="fas fa-map"></span>
                    </div>
                    <div className="text">
                      <p>
                        8/547 First Floor, Near Reliance Petrol Bunk, Lakshmi
                        Nagar, Erode.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="fas fa-headphones"></span>
                    </div>
                    <div className="text">
                      <p>
                        <a href="tel:6383664733"> +91 63836 64733</a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="fas fa-envelope"></span>
                    </div>
                    <div className="text">
                      <p>
                        <a
                          href="mailto:
                          techsposervice@gmail.com"
                        >
                          techsposervice@gmail.com
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-footer__bottom">
        <div className="container">
          <div className="site-footer__bottom-inner">
            <p className="site-footer__bottom-text">
              © 2023 Copyright by <a href="#">Techspo Services</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
