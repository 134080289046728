import React from 'react'

const Reachus = () => {
  return (
    <section class="contact-two">
    <div
      class="contact-two-bg jarallax"
      data-jarallax
      data-speed="0.2"
      data-imgPosition="50% 0%"
      style={{backgroundImage: "url(assets/images/backgrounds/contact-two-bg.jpg)"}}
    ></div>
    <div class="container">
      <div class="contact-two__inner">
        <div class="section-title section-title--two text-center">
          <span class="section-title__tagline">Contact Us</span>
          <h2 class="section-title__title">Get support for your problem</h2>
          <p class="section-title__text">
            We look forward to serving you and keeping your devices in
            optimal condition
          </p>
        </div>
        <div class="contact-two__details-box">
          <ul class="list-unstyled contact-two__details">
            <li>
              <div class="icon">
                <span class="fa fa-envelope"></span>
              </div>
              <div class="content">
                <span>Send us an email</span>
                <p>
                  <a href="mailto:techsposervice@gmail.com">techsposervice@gmail.com</a>
                </p>
              </div>
            </li>
            <li>
              <div class="icon">
                <span class="fa fa-phone"></span>
              </div>
              <div class="content">
                <span>Give us a call</span>
                <p><a href="tel:6383664733">+91 6383664733</a></p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Reachus