import React from "react";
import ToshibaHeader from "../Laptop/ToshibaHeader";
import ToshibaContent from "../Laptop/ToshibaContent";
import WorkingProcess from "../Laptop/WorkingProcess";

const Toshiba = () => {
  return (
    <>
      <ToshibaHeader />
      <WorkingProcess />
      <ToshibaContent />
    </>
  );
};

export default Toshiba;
