import React from "react";
import AsusHeader from "../Laptop/AsusHeader";
import AsusContent from "../Laptop/AsusContent";
import WorkingProcess from "../Laptop/WorkingProcess";

const Asus = () => {
  return (
    <>
      <AsusHeader />
      <WorkingProcess />
      <AsusContent />
    </>
  );
};

export default Asus;
