import React from "react";
import AboutHeader from "../About/AboutHeader";
import { WhoWeAre } from "../About/WhoWeAre";
import Experience from "../About/Experience";
import Skill from "../About/Skill";
import Reachus from "../About/Reachus";
import CompanyDetails from "../About/CompanyDetails";
import Testimonials from "../Home/Testimonials";

const About = () => {
  return (
    <>
      <AboutHeader />
      <WhoWeAre />
      <Experience />
      <Skill />
      <CompanyDetails />
      <Reachus />
      <Testimonials />
    </>
  );
};

export default About;
