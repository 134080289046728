import React from "react";

const ServicesSidebar = ({brand}) => {
  return (
    <>
      <div class="sidebar__single sidebar__get-in-touch">
        <h3 class="sidebar__title">Get in touch</h3>
        <ul class="sidebar__get-in-touch-list list-unstyled">
          
          <li>
            <div class="icon">
              <span class="icon-call"></span>
            </div>
            <div class="text">
              <h3>
                <a href="tel:+916383664733">+91 63836 64733</a>
              </h3>
            </div>
          </li>
          <li>
            <div class="icon">
              <span class="icon-mail"></span>
            </div>
            <div class="text">
              <h3>
                <a href="mailto:techsposervices@gmail.com">
                  techsposervices@gmail.com
                </a>
              </h3>
            </div>
          </li>
          <li>
            <div class="icon">
              <span class="icon-send"></span>
            </div>
            <div class="text">
              <h3>
                8/547D, First Floor, Near Reliance Petrol Bunk, Lakshmi Nagar,
                Bhavani, Erode - 638316
              </h3>
            </div>
          </li>
          <li>
            <div class="icon">
              <span class="icon-clock"></span>
            </div>
            <div class="text">
              <h3>09:00 AM - 22:00 PM</h3>
            </div>
          </li>
        </ul>
      </div>
      <div class="sidebar__single sidebar__tags">
        <h3 class="sidebar__title">Our Service Locations</h3>
        <div class="sidebar__tags-list">
          <a href="#">{brand} Service in Bhavani </a>
          <a href="#">{brand} Service in Komarapalayam </a>
          <a href="#">{brand} Service in Anthiyur </a>
          <a href="#">{brand} Service in Kavandapadi </a>
          <a href="#">{brand} Service in Chithode </a>
          <a href="#">{brand} Service in RN Pudur </a>
          <a href="#">{brand} Service in Agraharam </a>
          <a href="#">{brand} Service in Gobichettipalayam </a>
          <a href="#">{brand} Service in Appakudal </a>
         
        </div>
      </div>
      <div class="sidebar__single sidebar__tags">
        <h3 class="sidebar__title">Brands</h3>
        <div class="sidebar__tags-list">
          <a href="#">Dell</a>
          <a href="#">Lenova</a>
          <a href="#">HP</a>
          <a href="#">Acer</a>
          <a href="#">Asus</a>
          <a href="#">Toshiba</a>
          <a href="#">Sony Vaio</a>
          <a href="#">Samsung</a>
          <a href="#">Government Laptop</a>
        </div>
      </div>
      <div class="sidebar__single sidebar__booking">
        <div
          class="sidebar__booking-bg"
          style={{
            backgroundImage: "url(assets/images/blog/booking-bg-img.jpg)"
          }}
        ></div>
        <div class="sidebar__booking-content">
          <h3 class="sidebar__booking-title">Computer Repair Service</h3>
          <div class="sidebar__booking-btn-box">
            <a href="#" class="thm-btn sidebar__booking-btn">
              Book A Service
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesSidebar;
