import React from "react";

export default function Whychoose() {
  return (
    <section className="why-choose-one">
      <div className="container">
        <div className="section-title section-title--two text-center">
          <span className="section-title__tagline">Why Choose Us</span>
          <h2 className="section-title__title">Service Beyond The Screen</h2>
          <p className="section-title__text">
            we care for your devices like our own.
          </p>
        </div>
        <div className="row">
          <div
            className="col-xl-6 col-lg-6 wow fadeInUp"
            data-wow-delay="100ms"
          >
            <div className="why-choose-one__single">
              <div className="why-choose-one__icon">
                {/* <span className="icon-award"></span> */}
                <span className="icon-user"></span>
              </div>
              <div className="why-choose-one__content">
                <h3 className="why-choose-one__title">
                  <a href="/">Expert Technicians</a>
                </h3>
                <p className="why-choose-one__text">
                  Our team comprises skilled and certified technicians with
                  extensive experience in laptop, desktop and mobile device
                  repairs.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 wow fadeInUp"
            data-wow-delay="200ms"
          >
            <div className="why-choose-one__single">
              <div className="why-choose-one__icon">
                <span className="icon-quality-assurance"></span>
              </div>
              <div className="why-choose-one__content">
                <h3 className="why-choose-one__title">
                  <a href="/">Quality Parts</a>
                </h3>
                <p className="why-choose-one__text">
                  We prioritize the use of high-quality replacement parts to
                  ensure the longevity and reliability of our repairs, even
                  under warranty.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-xl-6 col-lg-6 wow fadeInUp"
            data-wow-delay="300ms"
          >
            <div className="why-choose-one__single">
              <div className="why-choose-one__icon">
                <span className="icon-service"></span>
              </div>
              <div className="why-choose-one__content">
                <h3 className="why-choose-one__title">
                  <a href="/">Prompt Service</a>
                </h3>
                <p className="why-choose-one__text">
                  We understand the urgency of getting your devices back in
                  working order. Our streamlined processes enable us to provide
                  timely repairs without compromising quality.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 wow fadeInUp"
            data-wow-delay="100ms"
          >
            <div className="why-choose-one__single">
              <div className="why-choose-one__icon">
                <span className="icon-warranty"></span>
              </div>
              <div className="why-choose-one__content">
                <h3 className="why-choose-one__title">
                  <a href="/">Waranty Service</a>
                </h3>
                <p className="why-choose-one__text">
                We stand by our commitment to providing excellent service and ensuring your satisfaction with our warranty offerings. We
                  guarantee the durability of our repairs.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 wow fadeInUp"
            data-wow-delay="400ms"
          >
            <div className="why-choose-one__single">
              <div className="why-choose-one__icon">
                <span className="icon-low-price"></span>
              </div>
              <div className="why-choose-one__content">
                <h3 className="why-choose-one__title">
                  <a href="/">Transparent Pricing</a>
                </h3>
                <p className="why-choose-one__text">
                  No hidden fees or surprises. We believe in transparent
                  pricing, and our quotes are provided upfront, so you know what
                  to expect.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 wow fadeInUp"
            data-wow-delay="200ms"
          >
            <div className="why-choose-one__single">
              <div className="why-choose-one__icon">
                <span className="icon-customer-service"></span>
              </div>
              <div className="why-choose-one__content">
                <h3 className="why-choose-one__title">
                  <a href="/">Customer Satisfaction</a>
                </h3>
                <p className="why-choose-one__text">
                  Your satisfaction is our priority. We strive to exceed your
                  expectations with every repair, providing a hassle-free
                  experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
