import React from "react";

const ContactMap = () => {
  return (
    <section class="google-map">
      <div class="container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3910.6742842398617!2d77.66999537661249!3d11.431170417820267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba9698fd772ef9d%3A0x8097459516efd765!2sLaptop%20Service%20Center%20in%20Erode%20-%20TECHSPO%20Services!5e0!3m2!1sen!2sin!4v1700155042585!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{border: "0"}}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
};

export default ContactMap;
