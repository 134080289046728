import React from "react";

const HPHeader = () => {
  return (
    <section class="page-header">
      <div
        class="page-header-bg"
        style={{
          backgroundImage: "url(assets/images/backgrounds/page-header-bg.jpg)"
        }}
      ></div>
      <div class="container">
        <div class="page-header__inner">
          <h2>HP LAPTOP SERVICE</h2>
          <p>Empowering your HP experience, one service at a time</p>
          <ul class="thm-breadcrumb list-unstyled">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <span>//</span>
            </li>
            <li>HP Laptop Service</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default HPHeader;
