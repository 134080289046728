import React from "react";
import ServicesSidebar from "./ServicesSidebar";
import WhatWeDo from "./WhatWeDo";

const GovernmentContent = () => {
  return (
    <section class="blog-sidebar">
      <div class="container">
        <div class="section-title section-title--two text-center">
          <span class="section-title__tagline">WHAT WE FIXING</span>
          <h2 class="section-title__title">Providing device solutions</h2>
          <p class="section-title__text">
            Precision in every click, perfection in every fix - that's our
            service promise
          </p>
        </div>
        <div class="row">
          <div class="col-xl-8 col-lg-7">
            <div class="blog-sidebar__left">
              <div class="blog-sidebar__img-box">
                <div class="blog-sidebar__img">
                  <img src="assets/images/blog/blog-sidebar-img-1.jpg" alt="" />
                </div>
                <div class="blog-sidebar__video-link">
                  <a
                    href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                    class="video-popup"
                  >
                    <div class="blog-sidebar__video-icon">
                      <span class="fa fa-play"></span>
                      <i class="ripple"></i>
                    </div>
                  </a>
                </div>
              </div>
              <p class="blog-sidebar__text-1">
                Government Laptop Service Center in Bhavani provides convenient
                service for your Government laptop problems. Our Experts have
                vast experience in the laptop service industry, allowing us to
                deliver cost-effective and quality repair service solutions for
                all of your Government laptop technical and gadget fix needs at
                nominal cost without compromising the quality service. Our
                Laptop Service technicians are highly trained experts, who have
                much expertise in the Government laptop chip-level service,
                Government Motherboard service, Government hard disk replacement
                service, Hinges rework and all Government Laptop issues.
              </p>
              <p class="blog-sidebar__text-2">
                Government laptop Service in Komarapalayam has solutions for
                many of your laptop services and requirements. Techspo Laptop
                Service Center serves out of all model Government Laptop Service
                Center in Erode like HP Government Laptop, Lenovo Government
                Laptop, Acer Government Laptop, Elcot Government Laptop etc.,
              </p>

              <div class="blog-sidebar__img-box-2">
                <div class="row">
                  <div class="col-xl-6">
                    <div class="blog-sidebar__single">
                      <div class="blog-sidebar__img-1">
                        <img
                          src="assets/images/blog/blog-sidebar-img-2.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="blog-sidebar__single">
                      <div class="blog-sidebar__img-2">
                        <img
                          src="assets/images/blog/blog-sidebar-img-3.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <WhatWeDo />
            </div>
          </div>
          <div class="col-xl-4 col-lg-5">
            <div class="sidebar">
              <div class="sidebar__single sidebar__category">
                <h3 class="sidebar__title">Laptop Accessories</h3>
                <ul class="sidebar__category-list list-unstyled">
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Adapter
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Battery
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Screen
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Keyboard
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Panels
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Motherboard
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Laptop
                      Touchpad
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Laptop Cooling
                      Fan
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Speakers
                    </a>
                  </li>
                </ul>
              </div>
              <div class="sidebar__single sidebar__category">
                <h3 class="sidebar__title">Government Laptop Major Services</h3>
                <ul class="sidebar__category-list list-unstyled">
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Laptop
                      Motherboard Service
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Laptop Hard
                      Disk Service
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Laptop Hinges
                      Re-work
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Laptop
                      Keyboard Replacement
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Laptop Screen
                      Replacement
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Laptop Battery
                      Replacement
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Laptop Panels
                      Replacement
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Broken Laptop
                      Service
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Government Laptop OS
                      Installation
                    </a>
                  </li>
                </ul>
              </div>

              <ServicesSidebar brand='Government Laptop'/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GovernmentContent;
