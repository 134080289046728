import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

const Skill = () => {
  const now = 60;

  return (
    <section class="skill-one about-page__skill-one">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6">
            <div class="skill-one__left">
              <div class="section-title text-left">
                <span class="section-title__tagline">
                  Our Skills & Expertise
                </span>
                <h2 class="section-title__title">
                  A Reputation Built on Trust
                </h2>
              </div>
              <p class="skill-one__text">
                Our satisfied customers are a testament to the trust we've
                earned over the years. Word of mouth is our most potent
                advertisement, and we take pride in the positive reviews and
                referrals from our community.
              </p>
              <p class="skill-one__text-2">
                Whether your laptop is lagging, your mobile is acting up, or you
                simply need expert advice, [Your Business Name] is here to serve
                you. Trust us to breathe new life into your devices, and let's
                embark on a journey of seamless technology together.
              </p>
              <div class="skill-one__progress">
                <div class="skill-one__progress-single">
                  <div class="bar">
                  <ProgressBar
                      min={0}
                      max={100}
                      now={84}
                      label="Rapid Diagnostics"
                      style={{ height: "30px" }}
                    />
                   
                  </div>
                </div>
                <div class="skill-one__progress-single">
                  <div class="bar">
                  <ProgressBar
                      min={0}
                      max={100}
                      now={95}
                      label="Replacment"
                      style={{ height: "30px" }}
                    />
                    
                  </div>
                </div>
                <div class="skill-one__progress-single">
                  <div class="bar marb-0">
                  <ProgressBar
                      min={0}
                      max={100}
                      now={86}
                      label="Device Repair"
                      style={{
                        height: "30px",
                      }}
                    />
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6">
            <div class="skill-one__right">
              <div
                class="skill-one__right-img-box wow slideInRight"
                data-wow-delay="100ms"
                data-wow-duration="2500ms"
              >
                <div class="skill-one__right-img">
                  <img src="assets/images/resources/skill-1-1.jpg" alt="" />
                  <div class="skill-one__video-link">
                    <a
                      href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                      class="video-popup"
                    >
                      <div class="skill-one__video-icon">
                        <span class="fa fa-play"></span>
                        <i class="ripple"></i>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="skill-one__video-content">
                  <p>Transparent diagnostics to efficient repairs</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skill;
