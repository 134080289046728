import React from "react";

function Header() {
  return (
    <>
      <header className="main-header">
        <nav className="main-menu">
          <div className="main-menu__wrapper">
            <div className="main-menu__wrapper-inner">
              <div className="main-menu__left">
                <div className="main-menu__logo">
                  <a href="/">
                    <img
                      src="assets/images/resources/logo_2_white.png"
                      alt="Techspo Services Logo"
                      width="100%"
                      // width="170"
                      // height="44"
                    />
                  </a>
                </div>
              </div>
              <div className="main-menu__main-menu-box">
                <a href="#" className="mobile-nav__toggler">
                  <i className="fa fa-bars"></i>
                </a>
                <ul className="main-menu__list">
                  <li className="dropdown current megamenu">
                    <a href="/">Home </a>
                  </li>
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li className="dropdown">
                    <a href="#">Laptop Service</a>
                    <ul className="shadow-box">
                      <li>
                        <a href="/dell">
                          Dell Laptop Service
                        </a>
                      </li>
                      <li>
                        <a href="/lenova">Lenova Laptop Service</a>
                      </li>
                      <li>
                        <a href="/hp">HP Laptop Service</a>
                      </li>
                      <li>
                        <a href="/acer">ACER Laptop Service</a>
                      </li>
                      <li>
                        <a href="/asus">Asus Laptop Service</a>
                      </li>
                      <li>
                        <a href="/toshiba">Toshiba Laptop Service</a>
                      </li>
                      <li>
                        <a href="/sony">Sony Vaio Laptop Service</a>
                      </li>
                      <li>
                        <a href="/samsung">Samsung Laptop Service</a>
                      </li>
                      <li>
                        <a href="/governmentlaptop">
                          Government Laptop Service
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a href="/desktopservice">Desktop Services</a>
                  </li>

                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="main-menu__right">
                <div className="main-menu__search-cart-call-box">
                  <div className="main-menu-two__btn-box">
                    <a
                      href="/bookservice"
                      className="thm-btn main-menu-two__btn"
                    >
                      Book A Service
                    </a>
                  </div>
                  <div className="main-menu__call">
                    <div className="main-menu__call-icon">
                      <i className="fas fa-phone"></i>
                    </div>
                    <div className="main-menu__call-content">
                      <p className="main-menu__call-sub-title text-white">
                        Call Us Anytime
                      </p>
                      <h5 className="main-menu__call-number">
                        <a href="tel:6383664733">+91 63836 64733</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
        {/* <!-- /.sticky-header__content --> */}
      </div>
      {/* <!-- /.stricky-header --> */}
    </>
  );
}

export default Header;
