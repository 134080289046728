import React from "react";
import DellHeader from "../Laptop/DellHeader";
import WorkingProcess from "../Laptop/WorkingProcess";
import DellContent from "../Laptop/DellContent";

const Dell = () => {
  return (
    <>
      <DellHeader />
      <WorkingProcess />
      <DellContent />
    </>
  );
};

export default Dell;
