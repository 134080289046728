import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

export default function Whatwedo() {
  const now = 60;
  return (
    <section className="skill-one">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="skill-one__left">
              <div className="section-title text-left">
                <span className="section-title__tagline">What we do</span>
                <h2 className="section-title__title">
                  We Specialize In Quick & <br />
                  Professional Repairs
                </h2>
              </div>
              <p className="skill-one__text">
                We take pride in offering efficient solutions for all your
                laptop service needs. We understand the pivotal role your laptop
                plays in your personal and professional life, and our commitment
                is to provide swift, reliable, and technologically advanced
                services.
              </p>
              <p className="skill-one__text-2">
                Continuously invest in the development of our technicians,
                ensuring they are equipped with the latest skills and knowledge
                to address the evolving complexities of laptop technology.
                Maintain the highest standards of quality by using premium parts
                and implementing rigorous testing procedures, ensuring the
                durability and performance of every repaired laptop.
              </p>
              <div className="skill-one__progress">
                <div className="skill-one__progress-single">
                  <div className="bar">
                    <ProgressBar
                      min={0}
                      max={100}
                      now={84}
                      label="Rapid Diagnostics"
                      style={{ height: "30px" }}
                    />
                  </div>
                </div>
                <div className="skill-one__progress-single">
                  <div className="bar">
                    <ProgressBar
                      min={0}
                      max={100}
                      now={95}
                      label="Advanced Repair Techniques"
                      style={{ height: "30px" }}
                    />
                  </div>
                </div>
                <div className="skill-one__progress-single">
                  <div className="bar marb-0">
                    <ProgressBar
                      min={0}
                      max={100}
                      now={86}
                      label="Premium Quality Parts & Service"
                      style={{
                        height: "30px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="skill-one__right">
              <div
                className="skill-one__right-img-box wow slideInRight"
                data-wow-delay="100ms"
                data-wow-duration="2500ms"
              >
                <div className="skill-one__right-img">
                  <img src="assets/images/resources/skill-1-1.jpg" alt="" />
                  <div className="skill-one__video-link">
                    <a
                      href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                      className="video-popup"
                    >
                      <div className="skill-one__video-icon">
                        <span className="fa fa-play"></span>
                        <i className="ripple"></i>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="skill-one__video-content">
                  <p>Improve gadget smartphone laptop repair services</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
