import React from "react";

const CompanyDetails = () => {
  return (
    <div class="company-details">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
            data-wow-delay="100ms"
          >
            <div class="company-details__single">
              <div class="company-details__single-inner">
                <div class="company-details__icon">
                  <span class="icon-management"></span>
                </div>
                <h3 class="company-details__title">
                  <a href="/">Who We Are</a>
                </h3>
                <p class="company-details__text">
                  Embrace innovation and remain adaptable to emerging
                  technologies, enabling us to provide cutting-edge solutions
                  that anticipate and exceed customer expectations. Prioritize
                  efficiency without compromising on the thoroughness of our
                  services, striving to provide timely solutions that respect
                  our customers' time and commitments.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
            data-wow-delay="100ms"
          >
            <div class="company-details__single">
              <div class="company-details__single-inner">
                <div class="company-details__icon">
                  <span class="icon-book"></span>
                </div>
                <h3 class="company-details__title">
                  <a href="/">Our Vision</a>
                </h3>
                <p class="company-details__text">
                  To be the leading provider of laptop services, setting the
                  standard for excellence and innovation in the tech repair
                  industry. We envision a future where every customer
                  experiences seamless, reliable, and technologically advanced
                  solutions, fostering a world where technology enriches lives
                  effortlessly.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
            data-wow-delay="100ms"
          >
            <div class="company-details__single">
              <div class="company-details__single-inner">
                <div class="company-details__icon">
                  <span class="icon-award"></span>
                </div>
                <h3 class="company-details__title">
                  <a href="/">Our Mission</a>
                </h3>
                <p class="company-details__text">
                  To deliver unparalleled laptop services with a focus on
                  precision, reliability, and customer satisfaction. We are
                  dedicated to exceeding customer expectations by leveraging our
                  technical expertise, staying at the forefront of industry
                  advancements, and fostering a customer-centric culture that
                  prioritizes transparency and efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
