import React from "react";
import DesktopHeader from "../Laptop/DesktopHeader";
import DesktopContent from "../Laptop/DesktopContent";

const DesktopService = () => {
  return (
    <>
      <DesktopHeader />
      <DesktopContent />
    </>
  );
};

export default DesktopService;
