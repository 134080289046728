import React from "react";
import SonyHeader from "../Laptop/SonyHeader";
import WorkingProcess from "../Laptop/WorkingProcess";
import SonyContent from "../Laptop/SonyContent";

const Sony = () => {
  return (
    <>
      <SonyHeader />
      <WorkingProcess />
      <SonyContent />
    </>
  );
};

export default Sony;
