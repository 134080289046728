import React from 'react'

const DellHeader = () => {
  return (
    <section class="page-header">
    <div
      class="page-header-bg"
      style={{backgroundImage: "url(assets/images/backgrounds/page-header-bg.jpg)"}}
    ></div>
    <div class="container">
      <div class="page-header__inner">
        <h2>DELL LAPTOP SERVICE</h2>
        <p>Empowering your Dell experience, one service at a time</p>
        <ul class="thm-breadcrumb list-unstyled">
          <li><a href="/">Home</a></li>
          <li><span>//</span></li>
          <li>Dell Laptop Service</li>
        </ul>
      </div>
    </div>
  </section>
  )
}

export default DellHeader