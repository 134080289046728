import React from 'react'
import HPHeader from '../Laptop/HPHeader'
import HPContent from '../Laptop/HPContent'
import WorkingProcess from '../Laptop/WorkingProcess'

const HP = () => {
  return (
    <>
    <HPHeader/>
    <WorkingProcess/>
    <HPContent/>
    </>
  )
}

export default HP