import React, { useEffect, useState } from "react";
import Odometer from "react-odometerjs";

const Experience = () => {
  const [services, setServices] = useState(0);
  const [happyCustomers, setHappyCustomers] = useState(0);
  const [expertise, setExpertise] = useState(0);
  const [satisfaction, setSatisfaction] = useState(0);
  useEffect(() => {
    const service = setTimeout(() => setServices(264), 3000);

    const Customers = setTimeout(() => setHappyCustomers(113), 3000);
    const Expert = setTimeout(() => setExpertise(5), 3000);
    const satisfy = setTimeout(() => setSatisfaction(99), 3000);

    return () => {
      clearTimeout(service);
      clearTimeout(Customers);
      clearTimeout(Expert);
      clearTimeout(satisfy);
    };
  }, []);
  return (
    <section class="experience-one about-page__experience">
      <div
        class="experience-one-shape-1 shapeMover"
        style={{
          backgroundIimage:
            "url(assets/images/shapes/experience-one-shape-1.png)",
        }}
      ></div>
      <div class="container">
        <div class="section-title text-center">
          <span class="section-title__tagline">About Experience</span>
          <h2 class="section-title__title">
            We Have Than 6 Years Experience in Gadget <br />
            Smartphone & Laptop Repair Services
          </h2>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="100ms">
            <div class="experience-one__single">
              <div class="experience-one__icon">
                <span class="icon-service"></span>
              </div>
              <div class="experience-one__content">
                <h3 class="experience-one__title">
                  <a href="/">Quality Services</a>
                </h3>
                <p class="experience-one__text text-justify">
                  Our technicians work diligently to provide efficient service
                  without compromising on quality. We strive to have your
                  computer, laptop and mobile devices repaired and returned to you as quickly as possible.
                </p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
            <div class="experience-one__single">
              <div class="experience-one__icon">
                <span class="icon-management"></span>
              </div>
              <div class="experience-one__content">
                <h3 class="experience-one__title">
                  <a href="/">Professional Team</a>
                </h3>
                <p class="experience-one__text">
                  Our team of experienced technicians possesses extensive
                  knowledge and expertise in computer repair and maintenance.
                  They stay updated with the latest technological advancements
                  to deliver outstanding results.
                </p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
            <div class="experience-one__single">
              <div class="experience-one__icon">
                <span class="icon-headphones"></span>
              </div>
              <div class="experience-one__content">
                <h3 class="experience-one__title">
                  <a href="/">Customer Support</a>
                </h3>
                <p class="experience-one__text">
                  We provide friendly and responsive customer support,
                  addressing your inquiries, providing updates, and offering
                  after-service assistance promptly. Your satisfaction is our
                  commitment.
                  {/* Customer satisfaction is our priority. We provide friendly and
                  responsive customer support, addressing your inquiries,
                  providing updates, and offering after-service assistance
                  promptly. Your satisfaction is our commitment. */}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <ul class="counter-one__list list-unstyled">
              <li class="counter-one__single">
                <h3 class="odometer" data-count="264">
                  <Odometer value={services} format="(ddd),dd" />
                  <span class="counter-one__plus">+</span>
                </h3>
                <p class="counter-one__text">Services Completed</p>
              </li>
              <li class="counter-one__single">
                <h3 class="odometer" data-count="113">
                  <Odometer value={happyCustomers} format="(ddd),dd" />
                  <span class="counter-one__plus">+</span>
                </h3>
                <p class="counter-one__text">Happy Customers</p>
              </li>
              <li class="counter-one__single">
                <h3 class="odometer" data-count="5">
                  <Odometer value={expertise} format="(ddd),dd" />
                  <span class="counter-one__plus">+</span>
                </h3>
                <p class="counter-one__text">Expertise</p>
              </li>
              <li class="counter-one__single">
                <h3 class="odometer" data-count="99">
                  <Odometer value={satisfaction} format="(ddd),dd" />
                  <span class="counter-one__plus">%</span>
                </h3>
                <p class="counter-one__text">Satisfactions</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
