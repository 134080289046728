import React from 'react'
import LenovaHeader from '../Laptop/LenovaHeader'
import WorkingProcess from '../Laptop/WorkingProcess'
import LenovaContent from '../Laptop/LenovaContent'

const Lenova = () => {
  return (
    <>
    <LenovaHeader/>
    <WorkingProcess/>
    <LenovaContent/>
    </>
  )
}

export default Lenova