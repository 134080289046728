import React from "react";

const WorkingProcess = () => {
  return (
    <section class="process-one">
      <div
        class="process-one__bg jarallax"
        data-jarallax
        data-speed="0.2"
        data-imgPosition="50% 0%"
        style={{
          backgroundImage: "url(assets/images/backgrounds/process-one-bg.jpg)",
        }}
      ></div>
      <div class="container">
        <div class="section-title text-center">
          <span class="section-title__tagline">Working Process</span>
          <h2 class="section-title__title">
            See our 4 easy steps how we works
          </h2>
        </div>
        <div class="row">
          <div class="col-xl-3 col-lg-6 col-md-6">
            <div class="process-one__single">
              <div class="process-one__icon">
                <span class="icon-computer"></span>
                <div class="process-one__count-box"></div>
              </div>
              <h3 class="process-one__title">Diagnosis</h3>
            </div>
          </div>

          <div class="col-xl-3 col-lg-6 col-md-6">
            <div class="process-one__single">
              <div class="process-one__icon">
                <span class="icon-send"></span>
                <div class="process-one__count-box"></div>
              </div>
              <h3 class="process-one__title">Problem Identified</h3>
            </div>
          </div>

          <div class="col-xl-3 col-lg-6 col-md-6">
            <div class="process-one__single">
              <div class="process-one__icon">
                <span class="icon-setting"></span>
                <div class="process-one__count-box"></div>
              </div>
              <h3 class="process-one__title">Repair & Replace Device</h3>
            </div>
          </div>

          <div class="col-xl-3 col-lg-6 col-md-6">
            <div class="process-one__single">
              <div class="process-one__icon">
                <span class="icon-product"></span>
                <div class="process-one__count-box"></div>
              </div>
              <h3 class="process-one__title">Quick Delivery</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkingProcess;
