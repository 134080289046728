import React from "react";

const WhatWeDo = () => {
  return (
    <div class="comment-one">
      <h3 class="comment-one__title">What We Do?</h3>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-laptop"></span>
            </div>
            <div class="content">
              <h3>Chip Level Service</h3>
              <p>
                Our technicians are proficient in repairing or replacing
                individual integrated circuits (chips) on the motherboard. This
                can include tasks such as diagnosing and fixing issues with the
                CPU, GPU, RAM, BIOS or other components.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-tap"></span>
            </div>
            <div class="content">
              <h3>Cracked Screen</h3>
              <p>
                We use only genuine replacement screens, ensuring that your
                laptop's display is restored to its original clarity and
                functionality.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-cell"></span>
            </div>
            <div class="content">
              <h3>Battery Replacement</h3>
              <p>
                Beyond replacement, we perform a battery health check to provide
                insights into your device's overall power efficiency and
                longevity.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-os"></span>
            </div>
            <div class="content">
              <h3>OS Update</h3>
              <p>
                Most modern operating systems allow you to enable automatic
                updates. This ensures that your system receives critical updates
                without manual intervention.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-setting"></span>
            </div>
            <div class="content">
              <h3>Water Damage</h3>
              <p>
                Accidentally exposed your laptop to water? Our water damage
                restoration services can assess and address the damage,
                salvaging your device whenever possible.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-smartphone-1"></span>
            </div>
            <div class="content">
              <h3>Charging Repair</h3>
              <p>
                Whether it's a loose charging port or a malfunctioning
                connector, to repair or replace these components, restoring
                seamless connectivity.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-speaker"></span>
            </div>
            <div class="content">
              <h3>Speaker Not Working</h3>
              <p>
                Whether it's a blown speaker or a malfunctioning component, we
                have the expertise to replace speakers with high-quality,
                compatible units.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-headphones"></span>
            </div>
            <div class="content">
              <h3>Headphone Jack</h3>
              <p>
                Sometimes, the issue lies in the audio jack or connectors. We
                can repair or replace these components to restore seamless audio
                connectivity.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-nfc"></span>
            </div>
            <div class="content">
              <h3>Wifi / Bluetooth</h3>
              <p>
                WiFi typically has a longer range compared to Bluetooth,
                allowing devices to connect over greater distances.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WhatWeDo;
