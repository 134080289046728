import React, { useEffect, useState } from "react";
import Odometer from "react-odometerjs";

export default function HappyClients() {
  const [services, setServices] = useState(0);
  const [happyCustomers, setHappyCustomers] = useState(0);
  const [expertise, setExpertise] = useState(0);
  const [satisfaction, setSatisfaction] = useState(0);
  useEffect(() => {
    const service = setTimeout(() => setServices(264), 3000);

    const Customers = setTimeout(() => setHappyCustomers(113), 3000);
    const Expert = setTimeout(() => setExpertise(5), 3000);
    const satisfy = setTimeout(() => setSatisfaction(99), 3000);

    return () => {
      clearTimeout(service);
      clearTimeout(Customers);
      clearTimeout(Expert);
      clearTimeout(satisfy);
    };
  }, []);
  return (
    <section className="counter-one">
      <div
        className="counter-one__bg jarallax"
        data-jarallax
        data-speed="0.2"
        data-imgPosition="50% 0%"
        style={{
          backgroundImage: "url(assets/images/backgrounds/counter-one-bg.jpg)",
        }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <ul className="counter-one__list list-unstyled">
              <li className="counter-one__single">
                <h3 className="odometer" data-count="264">
                  <Odometer value={services} format="(ddd),dd" />
                  <span className="counter-one__plus">+</span>
                </h3>
                <p className="counter-one__text">Services Completed</p>
              </li>
              <li className="counter-one__single">
                <h3 className="odometer" data-count="113">
                  <Odometer value={happyCustomers} format="(ddd),dd" />
                  <span className="counter-one__plus">+</span>
                </h3>

                <p className="counter-one__text">Happy Customers</p>
              </li>
              <li className="counter-one__single">
                <h3 className="odometer" data-count="5">
                  <Odometer value={expertise} format="(ddd),dd" />
                  <span className="counter-one__plus">+</span>
                </h3>
                <p className="counter-one__text">Expertise</p>
              </li>
              <li className="counter-one__single">
                <h3 className="odometer" data-count="99">
                  <Odometer value={satisfaction} format="(ddd),dd" />
                  <span className="counter-one__plus">%</span>
                </h3>
                <p className="counter-one__text">Satisfactions</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
