import React, { useEffect } from "react";
import Slider from "../Header/Slider";
import AboutExperience from "../Home/AboutExperience";
import Introduction from "../Home/Introduction";
import Whychoose from "../Home/Whychoose";
import Services from "../Home/Services";
import Testimonials from "../Home/Testimonials";
import HappyClients from "../Home/HappyClients";
import Whatwedo from "../Home/Whatwedo";
import Footer from "../Header/Footer";
import BottomtoTop from "../Header/BottomtoTop";
import WOW from "wowjs";
import Header from "../Header/Header";

export default function Home() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <div className="page-wrapper">
      {/* <Header/> */}
      <Slider />
      <AboutExperience />
      <Introduction />
      <Whychoose />
      <Services />
      <HappyClients />
      <Testimonials />
      <Whatwedo />
      {/* <Footer />
      <BottomtoTop/> */}
     
    </div>
  );
}
