import React from "react";
import ServicesSidebar from "./ServicesSidebar";
import WhatWeDo from "./WhatWeDo";

const ToshibaContent = () => {
  return (
    <section class="blog-sidebar">
      <div class="container">
        <div class="section-title section-title--two text-center">
          <span class="section-title__tagline">WHAT WE FIXING</span>
          <h2 class="section-title__title">Providing device solutions</h2>
          <p class="section-title__text">
            Precision in every click, perfection in every fix - that's our
            service promise
          </p>
        </div>
        <div class="row">
          <div class="col-xl-8 col-lg-7">
            <div class="blog-sidebar__left">
              <div class="blog-sidebar__img-box">
                <div class="blog-sidebar__img">
                  <img src="assets/images/blog/blog-sidebar-img-1.jpg" alt="" />
                </div>
                <div class="blog-sidebar__video-link">
                  <a
                    href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                    class="video-popup"
                  >
                    <div class="blog-sidebar__video-icon">
                      <span class="fa fa-play"></span>
                      <i class="ripple"></i>
                    </div>
                  </a>
                </div>
              </div>
              <p class="blog-sidebar__text-1">
                Toshiba Laptop Service Center in Bhavani provides convenient
                service for your Toshiba laptop problems. Our Experts have vast
                experience in the laptop service industry, allowing us to
                deliver cost-effective and quality repair service solutions for
                all of your Toshiba laptop technical and gadget fix needs at
                nominal cost without compromising the quality service. Our
                Laptop Service technicians are highly trained experts, who have
                much expertise in the Toshiba laptop chip-level service, Toshiba
                Motherboard service, Toshiba hard disk replacement service,
                Hinges rework and all Toshiba Laptop issues.
              </p>
              <p class="blog-sidebar__text-2">
                Toshiba laptop Service in Komarapalayam has solutions for many
                of your laptop services and requirements. Techspo Laptop Service
                Center serves out of all model Toshiba Laptop Service Center in
                Erode like Toshiba Inspiron, Toshiba Alienware, Toshiba Vostro,
                Toshiba Studio, Toshiba Xps, Toshiba Lattitude etc.,
              </p>

              <div class="blog-sidebar__img-box-2">
                <div class="row">
                  <div class="col-xl-6">
                    <div class="blog-sidebar__single">
                      <div class="blog-sidebar__img-1">
                        <img
                          src="assets/images/blog/blog-sidebar-img-2.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="blog-sidebar__single">
                      <div class="blog-sidebar__img-2">
                        <img
                          src="assets/images/blog/blog-sidebar-img-3.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <WhatWeDo />
            </div>
          </div>
          <div class="col-xl-4 col-lg-5">
            <div class="sidebar">
              <div class="sidebar__single sidebar__category">
                <h3 class="sidebar__title">Laptop Accessories</h3>
                <ul class="sidebar__category-list list-unstyled">
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Adapter
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Battery
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Screen
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Keyboard
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Panels
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Motherboard
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Laptop Touchpad
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Laptop Cooling
                      Fan
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Speakers
                    </a>
                  </li>
                </ul>
              </div>
              <div class="sidebar__single sidebar__category">
                <h3 class="sidebar__title">Toshiba Laptop Major Services</h3>
                <ul class="sidebar__category-list list-unstyled">
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Laptop
                      Motherboard Service
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Laptop Hard Disk
                      Service
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Laptop Hinges
                      Re-work
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Laptop Keyboard
                      Replacement
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Laptop Screen
                      Replacement
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Laptop Battery
                      Replacement
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Laptop Panels
                      Replacement
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Broken Laptop
                      Service
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span class="icon-play-1"></span>Toshiba Laptop OS
                      Installation
                    </a>
                  </li>
                </ul>
              </div>

              <ServicesSidebar  brand='Toshiba Laptop'/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ToshibaContent;
