import React from "react";

const DesktopWhatWeDo = () => {
  return (
    <div class="comment-one">
      <h3 class="comment-one__title">What We Do?</h3>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-computer"></span>
            </div>
            <div class="content">
              <h3>Computer Diagnosis and Troubleshooting: </h3>
              <p>
                Is your computer running slow, freezing, or displaying error
                messages? Our skilled technicians will conduct a thorough
                diagnosis to identify and resolve any hardware or software
                issues. Utilizing advanced tools and techniques, we pinpoint the
                root cause of the problem and deliver effective solutions.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-repair"></span>
            </div>
            <div class="content">
              <h3>Hardware Repair and Upgrades: </h3>
              <p>
                From faulty motherboards and power supplies to damaged graphics
                cards and hard drives, we have the expertise to handle a wide
                range of hardware repairs. Our technicians are proficient in
                component replacements and upgrades, including RAM, processors,
                and storage devices. We use only genuine parts to ensure optimal
                performance and reliability.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-os"></span>
            </div>
            <div class="content">
              <h3>Software Installation and Optimization: </h3>
              <p>
                Whether you need to install a new operating system or upgrade
                software applications, our experts have got you covered. We
                provide professional software installation services, ensuring
                proper configuration and optimization for seamless performance.
                We can also remove viruses, malware, and unwanted programs to
                enhance your computer's security and speed.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-shield"></span>
            </div>
            <div class="content">
              <h3>Data Recovery and Backup: </h3>
              <p>
                Accidentally deleted important files or experiencing data loss
                due to a hard drive failure? Our skilled data recovery
                specialists can help you retrieve lost data from damaged drives
                or accidental formatting. Additionally, we offer reliable data
                backup solutions to safeguard your valuable information against
                future incidents.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="comment-one__single">
        <ul class="fixing-one__points list-unstyled">
          <li>
            <div class="icon">
              <span class="icon-setting"></span>
            </div>
            <div class="content">
              <h3>Computer Cleaning and Maintenance: </h3>
              <p>
                Over time, computers can accumulate dust and debris, leading to
                overheating and decreased performance. Our comprehensive
                cleaning and maintenance services will restore your computer's
                optimal condition. We clean internal components, remove dust
                from cooling systems, and apply thermal paste to ensure
                efficient heat dissipation, prolonging the lifespan of your
                computer.
              </p>
            </div>
          </li>
        </ul>
      </div>
     
    </div>
  );
};

export default DesktopWhatWeDo;
