import React from "react";

export const WhoWeAre = () => {
  return (
    <section class="about-two about-page">
      <div class="container">
        <div class="row">
          <div class="col-xl-5 col-lg-6">
            <div class="about-two__left">
              <div class="about-two__main-progress-box">
                <div class="about-two__progress-single">
                  <div class="about-two__progress-box">
                    <div
                      class="circle-progress"
                      data-options='{ "value": 0.8,"thickness": 10,"emptyFill": "#f1f1f1","lineCap": "square", "size": 110, "fill": { "color": "#cf1f1f" } }'
                    ></div>

                    <div class="about-two__pack">
                      <p>85%</p>
                    </div>
                  </div>
                  <div class="about-two__progress-content">
                    <p>Repair Device</p>
                  </div>
                </div>
                <div class="about-two__progress-single">
                  <div class="about-two__progress-box">
                    <div
                      class="circle-progress"
                      data-options='{ "value": 0.9,"thickness": 10,"emptyFill": "#f1f1f1","lineCap": "square", "size": 110, "fill": { "color": "#cf1f1f" } }'
                    ></div>

                    <div class="about-two__pack">
                      <p>95%</p>
                    </div>
                  </div>
                  <div class="about-two__progress-content">
                    <p>Replace Device</p>
                  </div>
                </div>
              </div>
              <div class="about-two__img-box">
                <div class="about-two__img">
                  <img src="assets/images/resources/about-2-1.jpg" alt="" />
                </div>
                <div class="about-two__img-two">
                  <img src="assets/images/resources/about-2-2.jpg" alt="" />
                  <div class="about-two__video-link">
                    <a
                      href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                      class="video-popup"
                    >
                      <div class="about-two__video-icon">
                        <span class="fa fa-play"></span>
                        <i class="ripple"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="about-two__shape-1 shapeMover">
                <img src="assets/images/shapes/about-two-shape-1.png" alt="" />
              </div>
              <div class="about-two__shape-2 float-bob-y">
                <img src="assets/images/shapes/about-two-shape-2.png" alt="" />
              </div>
            </div>
          </div>
          <div class="col-xl-7 col-lg-6">
            <div class="about-two__right">
              <div class="section-title text-left">
                <span class="section-title__tagline">Who We Are</span>
                <h2 class="section-title__title">About Us</h2>
              </div>

              <p class="about-two__text-2">
                Welcome to Techspo Services, your one-stop solution for laptop, desktop
                and mobile repair services. we pride ourselves on being more
                than just a laptop, computer and mobile service provider; we are your
                dedicated tech partner committed to delivering exceptional
                service and unmatched expertise.Our mission is to provide
                prompt, reliable, and affordable repair services to keep you
                connected and productive.
              </p>
              <div class="section-title text-left">
                <h2 class="section-title__title">Our Commitment:</h2>
              </div>
              <p class="about-two__text-2">
                At the heart of Techspo Services is a commitment to
                excellence in service. We pride ourselves on delivering
                top-notch repairs for laptops, desktop and mobile devices, ensuring that
                your technology functions seamlessly. Our team of certified
                technicians is equipped with the latest tools and industry
                knowledge to diagnose and fix a wide range of issues.
              </p>
              <p class="about-two__text-2">
                At Techspo Services, efficiency is not just a goal; it's our
                standard. Experience the difference with our laptop services -
                where every solution is designed for speed, precision, and your
                peace of mind.
              </p>

              <a href="/contact" class="thm-btn">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
