import React from "react";

export default function AboutExperience() {
  return (
    <section className="experience-one">
      <div
        className="experience-one-shape-1 shapeMover"
        style={{backgroundImage: "url(assets/images/shapes/experience-one-shape-1.png)"}}
      ></div>
      <div className="container">
        <div className="section-title text-center">
          <span className="section-title__tagline">About Experience</span>
          <h2 className="section-title__title">
            We Have Than 6 Years Experience in <br />
            Laptop & Computer Repair Services
          </h2>
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-6 wow fadeInLeft" data-wow-delay="100ms">
            <div className="experience-one__single">
              <div className="experience-one__icon">
                <span className="icon-service"></span>
              </div>
              <div className="experience-one__content">
                <h3 className="experience-one__title">
                  <a href="/">Experience Matters</a>
                </h3>
                <p className="experience-one__text">
                  With over 6 years of hands-on experience in the industry,
                  we've witnessed the evolution of technology and mastered the
                  intricacies of laptop and mobile repair. Our seasoned
                  technicians bring a wealth of knowledge to every service,
                  ensuring that your devices are in capable hands.
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay="200ms">
            <div className="experience-one__single">
              <div className="experience-one__icon">
                <span className="icon-management"></span>
              </div>
              <div className="experience-one__content">
                <h3 className="experience-one__title">
                  <a href="/">Cutting-Edge Solutions</a>
                </h3>
                <p className="experience-one__text">
                  In a rapidly changing tech landscape, staying ahead is
                  non-negotiable. Our commitment to staying abreast of the
                  latest advancements in laptop and mobile technology equips us
                  with the knowledge to tackle even the most complex issues. We
                  don't just fix; we innovate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
